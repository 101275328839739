import { createSlice } from "@reduxjs/toolkit";

const initialState = { isVisible: true, selected: null};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.isVisible = !state.isVisible;
    },
    selectListItem(state, actions) {
      state.selected = actions.payload.listItem
    },
  },
});

export const sidebarActions = sidebarSlice.actions;
export default sidebarSlice.reducer;
