import React from "react";

import Box from "@mui/material/Box";
import { LinearProgress } from "@mui/material";

export default function Loading (props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {props?.isLoading ?? <LinearProgress color="primary" />}
      <Box
        display="flex"
        flex={1}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <img src="/images/icon.png" alt="" style={{ width: "100px" }} />
      </Box>
    </Box>
  );
};
