import React from "react";
import { useState, useEffect } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sidebarActions } from "../redux/features/SidebarSlice";
import { format, parseISO, isValid } from "date-fns";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import CustomAutocomplete from "../components/inputs/CustomAutocomplete";
import CustomDataGrid from "../components/data-display/CustomDataGrid";
import CustomFormHeader from "../components/layout/forms/CustomFormHeader";
import CustomTextField from "../components/inputs/CustomTextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Map from "../components/maps/Map";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ height: "100%", ...props.sx }}>{children}</Box>}
    </div>
  );
}

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const Property = () => {
  const propertyId = useParams().propertyId;

  const [tabIndex, setTabIndex] = useState(0);
  const onTabChangeHandler = (event, tabIndex) => {
    setTabIndex(tabIndex);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const onMoreClickHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMoreCloseHandler = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const [property, setProperty] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(sidebarActions.selectListItem({ listItem: "Property" }));
    const getProperties = async () => {
      var url = new URL(process.env.REACT_APP_API_HOST + "/findById");
      url.searchParams.set("qc", "opwPropertyFindAllQC");
      url.searchParams.set("id", propertyId);

      const response = await fetch(url, { credentials: "include" });

      if (!response.ok) {
        if (response.status === 401) {
          window.location.replace(
            `${process.env.REACT_APP_API_HOST}/oauth2/authorization/duo`
          );
        }
        throw new Error();
      }

      const responseData = await response.json();
      setProperty(responseData[`rdfs:member`][0]);
      setIsLoading(false);
    };
    getProperties();
  }, [dispatch, propertyId]);

  const navigate = useNavigate();
  const onBuildingRecordClickHandler = (params, event, details) => {
    navigate(`/buildings/` + params.id);
  };

  const onLeaseRecordClickHandler = (params, event, details) => {
    navigate(`/leases/` + params.id);
  };

  const onLegacyDocumentsClickHandler = () => {
    window.open(property["spi:opwExternalDocumentsURL"], "_blank", "noreferrer");
    setAnchorEl(null);
  };

  const onTririgaRecordViewClickHandler = () => {
    const url = `${process.env.REACT_APP_TRIRIGA_HOST}/pc/notify/link?recordId=${propertyId}`;
    window.open(url, "_blank", "noreferrer");
    setAnchorEl(null);
  };

  const BuildingsLinkedRecordGridModel = {
    columns: [
      { field: "triIdTX", headerName: "ID", flex: 1, minWidth: 80 },
      { field: "triNameTX", headerName: "Name", flex: 2, minWidth: 150 },
      { field: "triPrimaryUseCL", headerName: "Primary Use", flex: 2, minWidth: 150 },
      { field: "triTenureCL", headerName: "Tenure", flex: 2, minWidth: 150 },
      { field: "triAddressTX", headerName: "Address", flex: 2, minWidth: 150 },
      { field: "triCityTX", headerName: "Town/City", flex: 2, minWidth: 150 },
      { field: "triStateProvTX", headerName: "County", flex: 2, minWidth: 150 },
      { field: "triZipPostalTX", headerName: "Eircode", flex: 1, minWidth: 100 },
      { field: "triStatusCL", headerName: "TRIRIGA Status", flex: 1, minWidth: 150 },
    ],
    columnVisibility: {
      triPrimaryUseCL: false,
      triCityTX: false,
      triStateProvTX: false,
      triStatusCL: false,
    },
    filters: [{ id: 1, field: "triStatusCL", operator: "not", value: "Retired" }],
    queryCapability: "opwBuildingsFindAllQC",
    linkedResource: "opwPropertyFindByIdLR",
    path: "/findLinkedResourcesById/" + propertyId,
    sort: [{ field: "triIdTX", sort: "asc" }],
  };

  const reContractLinkedRecordGridModel = {
    columns: [
      { field: "triIdTX", headerName: "ID", flex: 1, minWidth: 80 },
      { field: "triNameTX", headerName: "Name", flex: 4, minWidth: 150 },
      { field: "triAccountingTypeLI", headerName: "Accounting Type", flex: 2, minWidth: 150 },
      {
        field: "triStartDA",
        type: "date",
        headerName: "Start Date",
        flex: 2,
        minWidth: 150,
        valueFormatter: ({ value }) => {
          if (!isValid(parseISO(value))) return "";
          return format(parseISO(value), "dd/MM/yyyy");
        },
      },
      {
        field: "triExpirationDA",
        type: "date",
        headerName: "End Date",
        flex: 2,
        minWidth: 150,
        valueFormatter: ({ value }) => {
          if (!isValid(parseISO(value))) return "";
          return format(parseISO(value), "dd/MM/yyyy");
        },
      },
      {
        field: "triLeaseTermDU",
        headerName: "Term",
        flex: 2,
        minWidth: 150,
        filterable: false,
        valueFormatter: ({ value }) => {
          let startIndex = 1;
          let formattedValue = "";
          for (const i of ["Years", "Months", "Weeks", "Days"]) {
            if (value.includes(i.charAt(0))) {
              if (startIndex !== 1) formattedValue += " ";
              formattedValue += value.substring(startIndex, value.indexOf(i.charAt(0))) + " " + i;
              startIndex = value.indexOf(i.charAt(0)) + 1;
            }
          }
          return formattedValue;
        },
      },
      {
        field: "triContractStatusCL",
        headerName: "Status",
        type: "singleSelect",
        valueOptions: [
          "Active",
          "Active-On-Hold",
          "Active-Operational",
          "Active-Pending Closure",
          "Discarded",
          "Disposed",
          "Expired",
          "Lease Renewed",
          "Overholding",
          "Pending",
          "Pre-Lease",
          "Surrendered",
          "Temporary Date",
          "Terminated",
          "Transferred",
        ],
        flex: 1,
        minWidth: 100,
      },
      {
        field: "triStatusCL",
        headerName: "TRIRIGA Status",
        type: "singleSelect",
        valueOptions: [
          "Revision In Progress",
          "Revision Accounting In Review",
          "Active",
          "On Hold",
          "Expired",
          "Terminated",
        ],
        flex: 1,
        minWidth: 100,
      },
    ],
    columnVisibility: {
      triAccountingTypeLI: false,
      triStatusCL: false,
    },
    filters: [
      { id: 1, field: "triStatusCL", operator: "not", value: "Expired" },
      { id: 2, field: "triStatusCL", operator: "not", value: "Terminated" },
    ],
    queryCapability: "opwREContractsFindAllQC",
    linkedResource: "opwREContractPropertyLR",
    path: "/findLinkedResourcesById/" + propertyId,
    sort: [{ field: "triIdTX", sort: "asc" }],
  };

  const [linkedRecord, setLinkedRecord] = useState("Buildings");
  const linkedRecordOnChangeHandler = (_, value) => {
    setLinkedRecord(value);
  };

  return (
    <Box
      sx={{
        overflow: "auto",
        px: 5,
        width: "100%",
        ...(tabIndex === 1 ? { display: "flex" } : {}),
      }}
    >
      <Box display="flex" flexDirection="column" flex={1} sx={{ pb: 4 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ my: 2.5 }}>
          <LinkRouter key="1" underline="hover" color="inherit" to="/">
            Home
          </LinkRouter>
          <LinkRouter key="2" underline="hover" color="inherit" to="/properties">
            Property
          </LinkRouter>
          <Typography key="3" color="text.primary">
            {isLoading ? "..." : property["spi:triIdTX"] + " - " + property["spi:triNameTX"]}
          </Typography>
        </Breadcrumbs>

        <Card sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Box display="flex" sx={{ borderBottom: 1, borderColor: "divider", mt: 1.5, mx: 3.5 }}>
            <Tabs value={tabIndex} onChange={onTabChangeHandler} sx={{ flex: 1 }}>
              <Tab label="General" />
              <Tab label="Map" />
            </Tabs>
            <Box display="flex" sx={{ alignItems: "center" }}>
              <IconButton size="small" color="inherit" onClick={onMoreClickHandler}>
                <MoreVertIcon fontSize="small" color="primary" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={onMoreCloseHandler}
              >
                {property["spi:opwExternalDocumentsURL"] && (
                  <MenuItem onClick={onLegacyDocumentsClickHandler}>
                    <ListItemIcon>
                      <FolderOpenIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="View Legacy Documents" />
                  </MenuItem>
                )}
                <MenuItem onClick={onTririgaRecordViewClickHandler}>
                  <ListItemIcon>
                    <OpenInNewIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Open Record in TRIRIGA" />
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          <TabPanel value={tabIndex} index={0}>
            <Box sx={{ p: 3.5, pt: 2.5, pb: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  mb: 1.5,
                }}
              ></Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <CustomTextField label="ID" value={property["spi:triIdTX"]} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField label="Accounting Cost Centre" value={property["spi:triCostCenterTX"]} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField label="Status" value={property["spi:triStatusCL"]} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField label="Name" value={property["spi:triNameTX"]} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField label="Description" value={property["spi:triDescriptionTX"]} multiline={2} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="Primary Use" value={property["spi:triPrimaryUseCL"]} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="Tenure" value={property["spi:triTenureCL"]} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField
                    label="Address"
                    value={property["spi:triAddressTX"]}
                    multiline={2}
                    location={{
                      latitude: property["spi:triGisLatitudeNU"],
                      longitude: property["spi:triGisLongitudeNU"],
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    label="City"
                    value={property["spi:triCityTX"]}
                    search={{ url: "/properties", field: "triCityTX", operator: "equals" }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    label="County"
                    value={property["spi:triStateProvTX"]}
                    search={{ url: "/properties", field: "triStateProvTX", operator: "equals" }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    label="Eircode"
                    value={property["spi:triZipPostalTX"]}
                    search={{ url: "/properties", field: "triZipPostalTX", operator: "equals" }}
                  />
                </Grid>
              </Grid>
            </Box>
          </TabPanel>

          <TabPanel
            value={tabIndex}
            index={1}
            sx={{ m: 3, mt: 2.5 }}
            style={{ display: "flex", flexDirection: "column", flex: 1 }}
          >
            <Map latitude={property["spi:triGisLatitudeNU"]} longitude={property["spi:triGisLongitudeNU"]} />
          </TabPanel>
        </Card>

        <TabPanel value={tabIndex} index={0}>
          <Card sx={{ mt: 3, p: 3.5, pt: 2, pb: 4 }}>
            <CustomFormHeader label="File Numbers" />
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <CustomTextField label="Tenure #" value={property["spi:opwPropertyManagmentNumberTX"]} />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField label="S&P #" value={property["spi:opwSandPNumberTX"]} />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField label="Maintenance #" value={property["spi:opwMaintenanceFileNumberTX"]} />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField label="Strong Box #" value={property["spi:opwStrongBoxNumberTX"]} />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField label="Folio #" value={property["spi:opwFolioNumberTX"]} />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomTextField label="Plan #" value={property["spi:opwPlanNumberTX"]} />
              </Grid>
            </Grid>
          </Card>
        </TabPanel>

        <TabPanel value={tabIndex} index={0}>
          <Card sx={{ mt: 3, p: 3.5, pt: 2, pb: 1 }}>
            <CustomFormHeader label="Linked Records" />
            <Grid container spacing={3} sx={{ mb: 1.5 }}>
              <Grid item xs={12} md={6} lg={4}>
                <CustomAutocomplete
                  label="Type"
                  options={[{ label: "Buildings" }, { label: "Leases" }]}
                  onChange={linkedRecordOnChangeHandler}
                />
              </Grid>
            </Grid>
            {linkedRecord === "Buildings" && (
              <CustomDataGrid
                staticHeight
                model={BuildingsLinkedRecordGridModel}
                onRowClick={onBuildingRecordClickHandler}
                showToolbar
              />
            )}
            {linkedRecord === "Leases" && (
              <CustomDataGrid
                staticHeight
                model={reContractLinkedRecordGridModel}
                onRowClick={onLeaseRecordClickHandler}
                showToolbar
              />
            )}
          </Card>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Property;
