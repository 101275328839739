import React from "react";
import { styled } from "@mui/system";
import useOslcQuery from "../../hooks/useOslcQuery";

import CustomDataGridToolbar from "./CustomDataGridToolbar"
import {
  DataGridPro,
  GridLogicOperator,
} from "@mui/x-data-grid-pro";

import Box from "@mui/material/Box";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  background: theme.palette.background.paper,
  border: 0,
  padding: 0,
  "& .MuiDataGrid-overlay": {
    background: theme.palette.background.paper,
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F5F5F5",
    borderRadius: 0,
    borderTop: '1px solid #D8D8D8',
  },
  "& .MuiDataGrid-pinnedColumns": {
    background: theme.palette.background.paper,
  },
  "& .MuiDataGrid-pinnedColumnHeaders": {
    background: theme.palette.background.paper,
  },
  "& .MuiDataGrid-cell": {
    alignItems: 'start'
  },
  "& .MuiDataGrid-columnHeader .MuiFormControl-root": {
    width: "100%",
  }
}));

const CustomDataGrid = (props) => {

  const {
    data,
    isLoading,
    error,
    totalCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortModel,
    onSortChangeHandler,
    filterModel,
    onFilterChangeHandler,
  } = useOslcQuery({
    filterModel: {
      items: props.model.filters,
    },
    path: props.model.path,
    queryCapability: props.model.queryCapability,
    linkedResource: props.model.linkedResource,
    sortModel: props.model.sort,
  });

  const gridData = [];
  if (data.hasOwnProperty(`rdfs:member`) && data[`rdfs:member`].length) {
    for (const item of data[`rdfs:member`]) {
      let property = { id: item[`dcterms:identifier`] };
      for (const column of props.model.columns) {
        property[`${column.field}`] = item[`spi:${column.field}`];
      }
      gridData.push(property);
    }
  }

  const { staticHeight } = props;

  return (
    <Box sx={{ height: staticHeight ? 500 : 0 , minHeight: "100%", width: 0, minWidth: "100%" }}>
      <StyledDataGrid
        columns={props.model.columns}
        {...(props.showToolbar && {
          components: {
            Toolbar: CustomDataGridToolbar,
          },
        })}
        componentsProps={{
          filterPanel: {
            logicOperators: [GridLogicOperator.And],
          },
        }}
        density="standard"
        {...(error && { error: true })}
        filterMode="server"
        filterModel={filterModel}
        getRowHeight={() => "auto"}
        initialState={{
          columns: {
            columnVisibilityModel: props.model.columnVisibility,
          },
          pagination: { paginationModel: { pageSize: pageSize } },
        }}
        loading={isLoading}
        page={page}
        pagination
        paginationMode="server"
        onFilterModelChange={onFilterChangeHandler}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        onPaginationModelChange={(newPage) => {
          setPage(newPage.page)
          setPageSize(newPage.pageSize)
        }}
        onRowClick={props.onRowClick}
        onSortModelChange={onSortChangeHandler}
        rowCount={totalCount}
        rows={gridData}
        pageSizeOptions={[10, 25, 50, 100]}
        slots={{
          toolbar: CustomDataGridToolbar,
        }}
        sortingMode="server"
        sortModel={sortModel}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
        }}
        unstable_headerFilters
      />
    </Box>
  );
};

CustomDataGrid.defaultProps = {
  height: 440,
};

export default CustomDataGrid;
