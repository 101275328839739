import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FolderIcon from "@mui/icons-material/Folder";
import LocationIcon from "@mui/icons-material/MapsHomeWork";
import RemoveIcon from '@mui/icons-material/Remove';

const StyledSidebar = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRight: theme.palette.divider.divider,
  boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  flexShrink: 0,
  height: "100%",
  overflow: "auto",
  transition: "all 0.25s",
}));

const Sidebar = (props) => {
  const isVisible = useSelector((state) => state.sidebar.isVisible);
  const selected = useSelector((state) => state.sidebar.selected);

  const [isContractDropdownOpen, setIsContractDropdownOpen] = React.useState(true);
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = React.useState(true);

  const sidebarWidth = 240;

  const contractDropdownOnClickHandler = () => {
    setIsContractDropdownOpen(!isContractDropdownOpen);
  };

  const locationDropdownOnClickHandler = () => {
    setIsLocationDropdownOpen(!isLocationDropdownOpen);
  };

  return (
    <StyledSidebar flexDirection="column" sx={{ ...(isVisible ? { width: sidebarWidth } : { width: "0px" }) }}>
      <div style={{ width: sidebarWidth }}>
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
        >
          <ListItemButton sx={{ px: 2.6 }} onClick={locationDropdownOnClickHandler}>
            <ListItemIcon>
              <LocationIcon />
            </ListItemIcon>
            <ListItemText primary="Locations" />
            {isLocationDropdownOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isLocationDropdownOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                selected={selected === "Property" && true}
                sx={{ pl: 4 }}
                component={RouterLink}
                to="/properties"
              >
                <ListItemIcon>
                  <RemoveIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary="Property" />
              </ListItemButton>
              <ListItemButton
                selected={selected === "Buildings" && true}
                sx={{ pl: 4 }}
                component={RouterLink}
                to="/buildings"
              >
                <ListItemIcon>
                  <RemoveIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary="Buildings" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton sx={{ px: 2.6 }} onClick={contractDropdownOnClickHandler}>
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText primary="Contracts" />
            {isContractDropdownOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isContractDropdownOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                selected={selected === "Leases" && true}
                sx={{ pl: 4 }}
                component={RouterLink}
                to="/leases"
              >
                <ListItemIcon>
                  <RemoveIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText primary="Leases" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </div>
    </StyledSidebar>
  );
};

export default Sidebar;
