import React from "react";
import { useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { format, parseISO, isValid } from "date-fns";
import { useDispatch } from "react-redux";
import { sidebarActions } from "../redux/features/SidebarSlice";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import CustomDataGrid from "../components/data-display/CustomDataGrid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const RELeaseQuery = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sidebarActions.selectListItem({ listItem: "Leases" }));
  }, [dispatch]);

  const navigate = useNavigate();
  const onRowClickHandler = (params, event, details) => {
    navigate("/leases/" + params.id);
  };

  const gridModel = {
    columns: [
      { field: "triIdTX", headerName: "ID", width: 80, minWidth: 80 },
      { field: "triNameTX", headerName: "Name", flex: 4, minWidth: 120 },
      { field: "triAccountingTypeLI", headerName: "Accounting Type", flex: 2, minWidth: 120 },
      {
        field: "triStartDA",
        type: "date",
        headerName: "Commencement Date",
        flex: 3,
        minWidth: 160,
        valueFormatter: ({ value }) => {
          if (!isValid(parseISO(value))) return "";
          return format(parseISO(value), "dd/MM/yyyy");
        },
      },
      {
        field: "triExpirationDA",
        type: "date",
        headerName: "Expiry Date",
        flex: 2,
        minWidth: 150,
        valueFormatter: ({ value }) => {
          if (!isValid(parseISO(value))) return "";
          return format(parseISO(value), "dd/MM/yyyy");
        },
      },
      {
        field: "triLeaseTermDU",
        headerName: "Term",
        flex: 2,
        minWidth: 150,
        filterable: false,
        valueFormatter: ({ value }) => {
          let startIndex = 1;
          let formattedValue = "";
          for (const i of ["Years", "Months", "Weeks", "Days"]) {
            if (value.includes(i.charAt(0))) {
              if (startIndex !== 1) formattedValue += " ";
              formattedValue += value.substring(startIndex, value.indexOf(i.charAt(0))) + " " + i;
              startIndex = value.indexOf(i.charAt(0)) + 1;
            }
          }
          return formattedValue;
        },
      },
      {
        field: "triBaseLeaseRateNU",
        headerName: "Base Rent",
        flex: 1,
        minWidth: 100,
        type: "number",
        valueGetter: (props) => {
          return props.value;
        },
        renderCell: (props) => {
          return (
            <NumericFormat
             prefix="€"
              allowNegative="false"
              decimalScale="2"
              fixedDecimalScale={true}
              defaultValue="0"
              displayType="text"
              thousandSeparator=","
              value={props.value}
            />
          );
        },
      },
      {
        field: "triContractStatusCL",
        headerName: "Status",
        type: "singleSelect",
        valueOptions: [
          "Active",
          "Active-On-Hold",
          "Active-Operational",
          "Active-Pending Closure",
          "Discarded",
          "Disposed",
          "Expired",
          "Lease Renewed",
          "Overholding",
          "Pending",
          "Pre-Lease",
          "Surrendered",
          "Temporary Date",
          "Terminated",
          "Transferred",
        ],
        flex: 1.5,
        minWidth: 100,
      },
      {
        field: "triStatusCL",
        headerName: "TRIRIGA Status",
        type: "singleSelect",
        valueOptions: [
          "Revision In Progress",
          "Revision Accounting In Review",
          "Active",
          "On Hold",
          "Expired",
          "Terminated",
        ],
        flex: 1,
        minWidth: 100,
      },
    ],
    columnVisibility: {
      triAccountingTypeLI: false,
      triStatusCL: false,
    },
    filters: [
      { id: 1, field: "triStatusCL", operator: "not", value: "Expired" },
      { id: 2, field: "triStatusCL", operator: "not", value: "Terminated" },
    ],
    path: "/findAll",
    queryCapability: "opwREContractsFindAllQC",
    sort: [{ field: "triIdTX", sort: "asc" }],
  };

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "auto",
        px: 5,
        width: "100%",
      }}
    >
      <Box display="flex" flexDirection="column" flex={1} sx={{ pb: 4 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ my: 2.5 }}>
          <LinkRouter key="1" underline="hover" color="inherit" to="/">
            Home
          </LinkRouter>
          <Typography key="2" color="text.primary">
            Leases
          </Typography>
        </Breadcrumbs>

        <Box display="flex" flexDirection="column" flex={1}>
          <Card sx={{ display: "flex", flexDirection: "column", flex: 1, p: 3.5, pt: 2.5, pb: 1 }}>
            <CustomDataGrid height="100%" model={gridModel} onRowClick={onRowClickHandler} showToolbar />
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default RELeaseQuery;
