import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query'

import { api } from './services/api'
import sidebarReducer from './features/SidebarSlice'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    sidebar: sidebarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch)