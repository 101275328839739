import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST,
  credentials: "include",
});

const baseQueryWithAuthRedirect = async (args, api, options) => {
  let result = await baseQuery(args, api, options);
  
  if (result?.error?.status === 401) {
    window.location.replace(
      `${process.env.REACT_APP_API_HOST}/oauth2/authorization/duo`
    );
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithAuthRedirect,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getLoggedInUser: builder.query({
      query: () => "auth/user",
    }),
  }),
});

export const { useGetLoggedInUserQuery } = api;
