import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sidebarActions } from "../redux/features/SidebarSlice";
import { styled } from "@mui/system";

import Box from "@mui/material/Box";
import CustomPortfolioCard from "../components/data-display/CustomPortfolioCard";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";

const portfolioData = [
  {
    name: "Buildings",
    category: "Locations",
    path: "buildings",
  },
  {
    name: "Property",
    category: "Locations",
    path: "properties",
  },
  {
    name: "Leases",
    category: "Contracts",
    path: "leases",
  },
];

const StyledDataCardContainer = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "15px",
  width: "100%",
  "& > :not(style)": {
    m: 1,
    width: 160,
    height: 160,
  },
}));

const StyledSearchBox = styled(TextField)(() => ({
  display: "none",
  marginBottom: "1.5rem",
}));

const Home = () => {
  const [portfolio, setPortfolio] = useState(portfolioData);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sidebarActions.selectListItem({ listItem: "Home" }));
  }, [dispatch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPortfolio(
        portfolioData.filter((value) => {
          return (
            value.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            value.category.toLowerCase().includes(searchTerm.toLowerCase())
          );
        })
      );
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <React.Fragment>
      
      <Box
        sx={{
          display: "block",
          overflow: "auto",
          p: 5,
          width: "100%",
        }}
      >
        <StyledSearchBox
          name="input-search"
          label="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          variant="filled"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <StyledDataCardContainer>
          {portfolio.length === 0 ? (
            <CustomPortfolioCard
              category={"Invalid Search"}
              name={"Not Found"}
            />
          ) : (
            portfolio.map((item, index) => {
              return (
                <CustomPortfolioCard
                  key={index}
                  category={item.category}
                  name={item.name}
                  path={item.path}
                />
              );
            })
          )}
        </StyledDataCardContainer>
      </Box>
    </React.Fragment>
  );
};

export default Home;
