import React from "react";
import { useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sidebarActions } from "../redux/features/SidebarSlice";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import CustomDataGrid from "../components/data-display/CustomDataGrid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const BuildingQuery = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sidebarActions.selectListItem({ listItem: "Buildings" }));
  }, [dispatch]);

  const navigate = useNavigate();
  const onRowClickHandler = (params) => {
    navigate("/buildings/" + params.id);
  };

  const gridModel = {
    columns: [
      { field: "triIdTX", headerName: "ID", flex: 1, minWidth: 150 },
      { field: "triNameTX", headerName: "Name", flex: 2, minWidth: 150 },
      { field: "triPrimaryUseCL", headerName: "Primary Use", flex: 2, minWidth: 150 },
      { field: "triTenureCL", headerName: "Tenure", flex: 1, minWidth: 150 },
      { field: "triAddressTX", headerName: "Address", flex: 2, minWidth: 150 },
      { field: "triCityTX", headerName: "Town/City", flex: 2, minWidth: 150 },
      { field: "triStateProvTX", headerName: "County", flex: 2, minWidth: 150 },
      { field: "triZipPostalTX", headerName: "Eircode", flex: 1, minWidth: 100 },
      {
        field: "triStatusCL",
        headerName: "TRIRIGA Status",
        type: "singleSelect",
        valueOptions: ["Active", "Revision In Progress", "Retired"],
        flex: 1,
        minWidth: 100,
      },
    ],
    columnVisibility: {
      triPrimaryUseCL: false,
      triCityTX: false,
      triStateProvTX: false,
      triStatusCL: false,
    },
    filters: [{ id: 1, field: "triStatusCL", operator: "not", value: "Retired" }],
    path: "/findAll",
    queryCapability: "opwBuildingsFindAllQC",
    sort: [{ field: "triIdTX", sort: "asc" }],
  };

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "auto",
        px: 5,
        width: "100%",
      }}
    >
      <Box display="flex" flexDirection="column" flex={1} sx={{ pb: 4 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ my: 2.5 }}>
          <LinkRouter key="1" underline="hover" color="inherit" to="/">
            Home
          </LinkRouter>
          <Typography key="2" color="text.primary">
            Buildings
          </Typography>
        </Breadcrumbs>

        <Box display="flex" flexDirection="column" flex={1}>
          <Card sx={{ display: "flex", flexDirection: "column", flex: 1, p: 3.5, pt: 2.5, pb: 1 }}>
            <CustomDataGrid height="100%" model={gridModel} onRowClick={onRowClickHandler} showToolbar />
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default BuildingQuery;
