import React from "react";
import { Route, Routes } from "react-router-dom";

import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import { useGetLoggedInUserQuery } from "./redux/services/api";

import Building from "./pages/Building";
import BuildingQuery from "./pages/BuildingQuery";
import Home from "./pages/Home";
import Layout from "./components/layout/Layout";
import Loading from "./components/layout/Loading";
import Property from "./pages/Property";
import PropertyQuery from "./pages/PropertyQuery";
import RELease from "./pages/RELease";
import RELeaseQuery from "./pages/RELeaseQuery";

LicenseInfo.setLicenseKey(
  "4417d08303ce048072bea74cc8ce2cdcTz01NzQyNCxFPTE3MDQ5NjYwMTQ4NzcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export default function App() {
  const { error, isLoading } = useGetLoggedInUserQuery();

  return (
    <ThemeProvider theme={theme}>
      {isLoading || error ? (
        <Loading isLoading={isLoading} error={error} />
      ) : (
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/buildings" element={<BuildingQuery />} />
            <Route path="/buildings/:buildingId" element={<Building />} />
            <Route path="/leases" element={<RELeaseQuery />} />
            <Route path="/leases/:leaseId" element={<RELease />} />
            <Route path="/properties" element={<PropertyQuery />} />
            <Route path="/properties/:propertyId" element={<Property />} />
          </Routes>
        </Layout>
      )}
    </ThemeProvider>
  );
}
