import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const CustomPortfolioCard = (props) => {
    
  const StyledPaper = styled(Paper)(() => ({
    "&:hover": {
      cursor: "pointer",
      boxShadow:
        "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
    },
  }));

  const StyledCardImage = styled("div")(() => ({
    backgroundImage: `url(/images/portfolio/${encodeURIComponent(props.name)}.png)`,
    backgroundSize: "cover",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "0.7rem",
    width: "50px",
    height: "50px",
  }));

  const navigate = useNavigate();
  const onClickHandler = () => {
    if (props.path) navigate(`/${props.path}`);
  }

  return (
    <StyledPaper onClick={onClickHandler}>
      <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column", pt: 4 }}>
        <StyledCardImage />
        <Typography variant="subtitle2" display="block" style={{ fontSize: "1rem", marginBottom: "0.2rem" }}>
          {props.name}
        </Typography>
        <Typography
          variant="overline"
          display="block"
          style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "0.6rem", lineHeight: "1" }}
        >
          {props.category}
        </Typography>
      </Box>
    </StyledPaper>
  );
};

export default CustomPortfolioCard;
