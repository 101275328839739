import React from "react";
import { styled } from "@mui/system";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import Box from "@mui/material/Box";

const StyledGridToolbarWrapper = styled(Box)(() => ({
  "& .MuiDataGrid-toolbarContainer": {
    padding: 5,
  },
}));

const CustomDataGridToolbar = (props) => {
  return (
    <Box sx={{pt: "2px"}}>
      <StyledGridToolbarWrapper>
        <GridToolbarContainer>
          <Box display="flex" flex="1">
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton sx={{ml: 1}}/>
            {/*<GridToolbarDensitySelector sx={{ml: 1}}/>*/}
          </Box>
          <GridToolbarExport />
        </GridToolbarContainer>
      </StyledGridToolbarWrapper>
      </Box>
  );
};

export default CustomDataGridToolbar;
