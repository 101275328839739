import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

const render = (status) => {
  if (status === Status.LOADING) {
    return <p>Loading...</p>;
  }
  if (status === Status.FAILURE) {
    return <p>Error...</p>;
  }
  return null;
};

const MapComponent = ({ center, zoom, children, style }) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      const map_01 = new window.google.maps.Map(ref.current, {
        center: center,
        zoom: zoom,
        tilt: 45,
        mapId: "c76e38f647e1e86c",
      });
      setMap(map_01);
    }
  }, [ref, map, center, zoom]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};

const Map = ({ latitude, longitude }) => {
  const [zoom] = React.useState(15);
  const [center] = React.useState({
    lat: latitude || 0,
    lng: longitude || 0,
  });

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      {!latitude || !longitude ? (
        <Alert variant="filled" severity="error" sx={{ mb: 2.5 }}>
          Warning! Invalid GIS Longitude and/or Latitude value(s)
        </Alert>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            mb: 1,
          }}
        ></Box>
      )}
      <Box display="flex" flexDirection="column" flex={1}>
        <Wrapper apiKey={"AIzaSyBXD78ELcROuz7DBor4GOGaVksBBix44P8"} render={render}>
          <MapComponent center={center} zoom={zoom} style={{ width: "100%", height: "100%", borderRadius: 4 }}>
            <Marker position={center} />
          </MapComponent>
        </Wrapper>
      </Box>
    </Box>
  );
};

export default Map;
