import React from "react";
import { useNavigate } from "react-router-dom";
import { format, parseISO, isValid } from "date-fns";

import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import SearchIcon from "@mui/icons-material/Search";

const oslcDataFormatter = (type, value) => {
  let formattedValue = "";
  switch (type) {
    case "date":
      if (!isValid(parseISO(value))) return "";
      formattedValue = format(parseISO(value), "dd/MM/yyyy");
      break;
    case "duration":
      let startIndex = 1;
      for (const i of ["Years", "Months", "Weeks", "Days"]) {
        if (value.includes(i.charAt(0))) {
          if (startIndex !== 1) formattedValue += " ";
          formattedValue += value.substring(startIndex, value.indexOf(i.charAt(0))) + " " + i;
          startIndex = value.indexOf(i.charAt(0)) + 1;
        }
      }
      break;
    default:
      formattedValue = value;
  }
  return formattedValue;
};

const CustomTextField = (props) => {
  let value = "";
  if (props.value) value = oslcDataFormatter(props.type, props.value);

  const navigate = useNavigate();
  const onFieldSeachClickHandler = () => {
    navigate(props.search.url, {
      state: { filter: { columnField: props.search.field, operatorValue: props.search.operator, value: value } },
    });
  };

  const onMapClickHandler = () => {
    const url =
      "https://www.google.com/maps/search/?api=1&query=" + props.location.latitude + "%2C" + props.location.longitude;
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <TextField
      label={props.label}
      variant="outlined"
      size="small"
      value={value}
      fullWidth
      readOnly
      {...(props.hasOwnProperty("multiline") ? { multiline: true, rows: props.multiline } : {})}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly: true,
        ...(props.location && {
          endAdornment: (
            <IconButton edge="end" onClick={onMapClickHandler} sx={{ mr: 0 }}>
              <LocationSearchingIcon fontSize="small" />
            </IconButton>
          ),
        }),
        ...(props.search && {
          endAdornment: (
            <IconButton edge="end" onClick={onFieldSeachClickHandler}>
              <SearchIcon fontSize="small" />
            </IconButton>
          ),
        }),
      }}
    />
  );
};

export default CustomTextField;
