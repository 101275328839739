import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { useDispatch } from "react-redux";

import { sidebarActions } from "../../redux/features/SidebarSlice";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import MenuIcon from "@mui/icons-material/Menu";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main,
  position: "static",
}));

const Header = () => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const menuIconOnClickHandler = () => {
    dispatch(sidebarActions.toggleSidebar());
  };

  const navigate = useNavigate();
  const onHeaderLogoClickHandler = () => {
    navigate("/");
  };

  const onLogoutClickHandler = () => {
    fetch(`${process.env.REACT_APP_API_HOST}/logout`, {
      method: "POST",
      credentials: "include",
    }).then(() => {
      setOpen(true);
    });
  };

  return (
    <React.Fragment>
      <StyledAppBar>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={menuIconOnClickHandler}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            display="flex"
            onClick={onHeaderLogoClickHandler}
            sx={{
              alignItems: "center",
              flex: 1,
              "&:hover": { cursor: "pointer" },
            }}
          >
            <img src="/images/logo.png" alt="" style={{ width: "100px" }} />
            <Typography
              variant="h6"
              component="div"
              sx={{ flex: 1, fontWeight: 300, mt: "-2px" }}
            >
              TRIRIGA Visualiser
            </Typography>
          </Box>
          <Button color="inherit" onClick={onLogoutClickHandler}>
            Logout
          </Button>
        </Toolbar>
      </StyledAppBar>
      <Dialog fullScreen open={open}>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <img src="/images/icon.png" alt="" style={{ width: "100px" }} />
          <Typography sx={{mt: 1}}>Logout Successful</Typography>
        </Box>
        
        <DialogActions>
          <Button
            onClick={() =>
              window.location.replace(process.env.REACT_APP_API_HOST)
            }
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Header;
