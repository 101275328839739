import React from "react";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const CustomFormHeader = (props) => {
  return (
    <React.Fragment>
      <Typography color="secondary" gutterBottom variant="overline">
        {props.label}
      </Typography>
      <Divider sx={{ mb: 3.5 }} />
    </React.Fragment>
  );
};

export default CustomFormHeader;
