import React from "react";
import { NumericFormat } from "react-number-format";

import TextField from "@mui/material/TextField";

const CustomNumberField = (props) => {

  const MUITextFieldProps = {
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
    inputProps: {
      readOnly: true,
    },
    label: props.label,
    size: "small",
    variant: "outlined",
  };

  return (
    <NumericFormat
      customInput={TextField}
      decimalScale={props.decimalScale}
      fixedDecimalScale={props.fixedDecimalScale}
      prefix={props.prefix}
      thousandSeparator=","
      value={props.value}
      {...MUITextFieldProps}
    />
  );
};

CustomNumberField.defaultProps = {
  allowNegative: true,
};

export default CustomNumberField;
