import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const CustomAutocomplete = (props) => {
  const flatProps = {
    options: props.options.map((option) => option.label),
  };

  return (
    <Autocomplete
      {...flatProps}
      defaultValue={flatProps.options[0]}
      disableClearable
      disablePortal
      size="small"
      onChange={(e, value) => props.onChange(e, value)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label={props.label}
          variant="outlined"
        />
      )}
    />
  );
};

export default CustomAutocomplete;
