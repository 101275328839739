import React from "react";
import { styled } from "@mui/system";

import Header from "./Header";
import Sidebar from "./Sidebar";

import Box from "@mui/material/Box";

const StyledMain = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
}));

const Layout = (props) => {
  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Header></Header>
      <Box flex={1} overflow="auto" flexDirection="column">
        <StyledMain height="100%" display="flex" flexDirection="row">
          <Sidebar></Sidebar>
          {props.children}
        </StyledMain>
      </Box>
    </Box>
  );
};

export default Layout;
