import React from "react";
import { useState, useEffect } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sidebarActions } from "../redux/features/SidebarSlice";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import CustomAutocomplete from "../components/inputs/CustomAutocomplete";
import CustomDataGrid from "../components/data-display/CustomDataGrid";
import CustomFormHeader from "../components/layout/forms/CustomFormHeader";
import CustomNumberField from "../components/inputs/CustomNumberField";
import CustomTextField from "../components/inputs/CustomTextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ height: "100%", ...props.sx }}>{children}</Box>}
    </div>
  );
}

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const RELease = () => {
  const leaseId = useParams().leaseId;

  const [tabIndex, setTabIndex] = useState(0);
  const onTabChangeHandler = (event, tabIndex) => {
    setTabIndex(tabIndex);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const onMoreClickHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMoreCloseHandler = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const [lease, setLease] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(sidebarActions.selectListItem({ listItem: "Leases" }));
    const getProperties = async () => {
      var url = new URL(process.env.REACT_APP_API_HOST + "/findById");
      url.searchParams.set("qc", "opwREContractsFindAllQC");
      url.searchParams.set("id", leaseId);
      
      const response = await fetch(url, { credentials: "include" });

      if (!response.ok) {
        if (response.status === 401) {
          window.location.replace(
            `${process.env.REACT_APP_API_HOST}/oauth2/authorization/duo`
          );
        }
        throw new Error();
      }

      const responseData = await response.json();
      console.log(responseData);
      setLease(responseData[`rdfs:member`][0]);
      setIsLoading(false);
    };
    getProperties();
  }, [dispatch, leaseId]);

  const navigate = useNavigate();
  const onBuildingsLinkedRecordClickHandler = (params, event, details) => {
    navigate(`/buildings/` + params.id);
  };

  const onLegacyDocumentsClickHandler = () => {
    window.open(lease["spi:opwExternalDocumentsURL"], "_blank", "noreferrer");
    setAnchorEl(null);
  };

  const onTririgaRecordViewClickHandler = () => {
    const url = `${process.env.REACT_APP_TRIRIGA_HOST}/pc/notify/link?recordId=${leaseId}`;
    window.open(url, "_blank", "noreferrer");
    setAnchorEl(null);
  };

  const buildingsLinkedRecordGridModel = {
    columns: [
      { field: "triIdTX", headerName: "ID", flex: 1, minWidth: 80 },
      { field: "triNameTX", headerName: "Name", flex: 2, minWidth: 150 },
      { field: "triPrimaryUseCL", headerName: "Primary Use", flex: 2, minWidth: 150 },
      { field: "triTenureCL", headerName: "Tenure", flex: 2, minWidth: 150 },
      { field: "triAddressTX", headerName: "Address", flex: 2, minWidth: 150 },
      { field: "triCityTX", headerName: "Town/City", flex: 2, minWidth: 150 },
      { field: "triStateProvTX", headerName: "County", flex: 2, minWidth: 150 },
      { field: "triZipPostalTX", headerName: "Eircode", flex: 1, minWidth: 100 },
      {
        field: "triStatusCL",
        headerName: "TRIRIGA Status",
        type: "singleSelect",
        valueOptions: ["Active", "Revision In Progress", "Retired"],
        flex: 1,
        minWidth: 100,
      },
    ],
    columnVisibility: {
      triPrimaryUseCL: false,
      triCityTX: false,
      triStateProvTX: false,
      triStatusCL: false,
    },
    filters: [{ id: 1, field: "triStatusCL", operator: "not", value: "Retired" }],
    queryCapability: "opwBuildingsFindAllQC",
    linkedResource: "opwBuildingREContractLR",
    path: "/findLinkedResourcesById/" + leaseId,
    sort: [{ field: "triIdTX", sort: "asc" }],
  };

  const contactsLinkedRecordGridModel = {
    columns: [
      { field: "triRole-triRoleTX", headerName: "Role", flex: 2, minWidth: 150 },
      { field: "triPeople-triNameTX", headerName: "Name", flex: 2, minWidth: 150 },
      {
        field: "triPeople-triEmailTX",
        headerName: "Email",
        flex: 2,
        minWidth: 150,
        valueGetter: (props) => {
          return props.value;
        },
        renderCell: (props) => {
          return (
            <a href={`mailto:${props.value}`} target="_blank" rel="noreferrer">
              {props.value}
            </a>
          );
        },
      },
      { field: "triPeople-triWorkPhoneTX", headerName: "Phone", flex: 2, minWidth: 150 },
    ],
    columnVisibility: {},
    filters: [],
    queryCapability: "opwContactRoleFindAllQC",
    linkedResource: "opwREContractFindByIdLR",
    path: "/findLinkedResourcesById/" + leaseId,
    sort: [{ field: "triRole-triRoleTX", sort: "asc" }],
  };

  const spaceUseAgreementlinkedRecordGridModel = {
    columns: [
      { field: "triNameTX", headerName: "SUA Name", flex: 2, minWidth: 150 },
      { field: "triOrganization-triOrgNameTX", headerName: "Organisation Name", flex: 2, minWidth: 150 },
      {
        field: "triSUARentableNU",
        headerName: "SUA Rentable Area",
        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }
          const valueFormatted = Number(params.value.toFixed(2));
          return `${valueFormatted}`;
        },
        flex: 2,
        minWidth: 150,
      },
      {
        field: "triContractPctNU",
        headerName: "Contract %",
        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }
          const valueFormatted = Number(params.value.toFixed(2));
          return `${valueFormatted}`;
        },
        flex: 2,
        minWidth: 150,
      },
      { field: "opwPayerTypeLI", headerName: "Payer Type", flex: 2, minWidth: 150 },
      { field: "opwTotalParkingSpacesNU", headerName: "Parking Spaces", flex: 2, minWidth: 150 },
      { field: "triStatusCL", headerName: "Status", flex: 2, minWidth: 150 },
    ],
    columnVisibility: {},
    filters: [],
    queryCapability: "opwSpaceUseAgreementFindAllQC",
    linkedResource: "opwSpaceUseAgreementFindByREContractIdRS",
    path: "/findLinkedResourcesById/" + leaseId,
    sort: [{ field: "triNameTX", sort: "asc" }],
  };

  const [linkedRecord, setLinkedRecord] = useState("Buildings");
  const linkedRecordOnChangeHandler = (_, value) => {
    setLinkedRecord(value);
  };

  return (
    <Box
      sx={{
        overflow: "auto",
        px: 5,
        width: "100%",
        ...(tabIndex === 1 ? { display: "flex" } : {}),
      }}
    >
      <Box display="flex" flexDirection="column" flex={1} sx={{ pb: 4 }}>
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ my: 2.5 }}>
          <LinkRouter key="1" underline="hover" color="inherit" to="/">
            Home
          </LinkRouter>
          <LinkRouter key="2" underline="hover" color="inherit" to="/leases">
            Leases
          </LinkRouter>
          <Typography key="3" color="text.primary">
            {isLoading ? "..." : lease["spi:triIdTX"] + " - " + lease["spi:triNameTX"]}
          </Typography>
        </Breadcrumbs>

        <Card sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Box display="flex" sx={{ borderBottom: 1, borderColor: "divider", mt: 1.5, mx: 3.5 }}>
            <Tabs value={tabIndex} onChange={onTabChangeHandler} sx={{ flex: 1 }}>
              <Tab label="General" />
            </Tabs>
            <Box display="flex" sx={{ alignItems: "center" }}>
              <IconButton size="small" color="inherit" onClick={onMoreClickHandler}>
                <MoreVertIcon fontSize="small" color="primary" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={onMoreCloseHandler}
              >
                {lease["spi:opwExternalDocumentsURL"] && (
                  <MenuItem onClick={onLegacyDocumentsClickHandler}>
                    <ListItemIcon>
                      <FolderOpenIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="View Legacy Documents" />
                  </MenuItem>
                )}
                <MenuItem onClick={onTririgaRecordViewClickHandler}>
                  <ListItemIcon>
                    <OpenInNewIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Open Record in TRIRIGA" />
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          <TabPanel value={tabIndex} index={0}>
            <Box sx={{ p: 3.5, pt: 2.5, pb: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  mb: 1.5,
                }}
              ></Box>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="ID" value={lease["spi:triIdTX"]} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField label="Contract Status" value={lease["spi:triContractStatusCL"]} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField label="Name" value={lease["spi:triNameTX"]} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextField label="Description" value={lease["spi:triDescriptionTX"]} multiline={2} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField label="Accounting Type" value={lease["spi:triAccountingTypeLI"]} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField label="Primary use" value={lease["spi:triContractPrimaryUseCL"]} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomNumberField
                    label="Base Rent"
                    prefix="€"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={lease["spi:triBaseLeaseRateNU"]}
                  />
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </Grid>
            </Box>
          </TabPanel>
        </Card>

        <TabPanel value={tabIndex} index={0}>
          <Card sx={{ mt: 3, p: 3.5, pt: 2, pb: 4 }}>
            <CustomFormHeader label="Key Dates" />
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <CustomTextField label="Commencement Date" type="date" value={lease["spi:triStartDA"]} />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomTextField label="Expiry Date" type="date" value={lease["spi:triExpirationDA"]} />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField label="Term" type="duration" value={lease["spi:triLeaseTermDU"]} />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomTextField label="Signed Date" type="date" value={lease["spi:triSignedDA"]} />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomTextField label="Original Start Date" type="date" value={lease["spi:triOriginalStartDA"]} />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomTextField label="Original End Date" type="date" value={lease["spi:triOriginalExpirationDA"]} />
              </Grid>
            </Grid>
          </Card>
        </TabPanel>

        <TabPanel value={tabIndex} index={0}>
          <Card sx={{ mt: 3, p: 3.5, pt: 2, pb: 1 }}>
            <CustomFormHeader label="Linked Records" />
            <Grid container spacing={3} sx={{ mb: 1.5 }}>
              <Grid item xs={12} md={6} lg={4}>
                <CustomAutocomplete
                  label="Type"
                  options={[{ label: "Buildings" }, { label: "Contacts" }, { label: "Occupancy" }]}
                  onChange={linkedRecordOnChangeHandler}
                />
              </Grid>
            </Grid>
            {linkedRecord === "Contacts" && (
              <CustomDataGrid staticHeight model={contactsLinkedRecordGridModel} showToolbar />
            )}
            {linkedRecord === "Occupancy" && (
              <CustomDataGrid staticHeight model={spaceUseAgreementlinkedRecordGridModel} showToolbar />
            )}
            {linkedRecord === "Buildings" && (
              <CustomDataGrid
                staticHeight
                model={buildingsLinkedRecordGridModel}
                onRowClick={onBuildingsLinkedRecordClickHandler}
                showToolbar
              />
            )}
          </Card>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default RELease;
