import { createTheme } from "@mui/material/styles";

import "@fontsource/ibm-plex-sans/300.css";
import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/700.css";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00544e",
    },
    secondary: {
      main: "#af9f6a",
    },
    background: {
      default: "#ecece6",
    },
    info: {
      main: "#662e63",
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: "IBM Plex Sans",
  },
});

export default theme;
