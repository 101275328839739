import React from "react";
import { useState, useEffect } from "react";

const useOslcQuery = (config) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortModel, setSortModel] = useState(config.sortModel);
  const [filterModel, setFilterModel] = useState(config.filterModel);

  useEffect(() => {
    const getProperties = async () => {
      var url = new URL(process.env.REACT_APP_API_HOST + config.path);

      url.searchParams.set("qc", config.queryCapability);
      url.searchParams.set("page", page + 1);
      url.searchParams.set("size", pageSize);

      if (config.linkedResource)
        url.searchParams.set("lr", config.linkedResource);

      if (filterModel.items.length > 0) {
        let where = [];
        for (const filter of filterModel.items) {
          switch (filter.operator) {
            case "after":
            case ">":
              filter.value &&
                where.push(`spi:${filter.field}>"${filter.value}"`);
              break;
            case "onOrAfter":
            case ">=":
              filter.value &&
                where.push(`spi:${filter.field}>="${filter.value}"`);
              break;
            case "before":
            case "<":
              filter.value &&
                where.push(`spi:${filter.field}<"${filter.value}"`);
              break;
            case "onOrBefore":
            case "<=":
              filter.value &&
                where.push(`spi:${filter.field}<="${filter.value}"`);
              break;
            case "contains":
              filter.value &&
                where.push(`spi:${filter.field}="%${filter.value}%"`);
              break;
            case "startsWith":
              filter.value &&
                where.push(`spi:${filter.field}="${filter.value}%"`);
              break;
            case "endsWith":
              filter.value &&
                where.push(`spi:${filter.field}="%${filter.value}"`);
              break;
            case "isAnyOf":
              if (filter.value) {
                if (filter.value.length !== 0) {
                  let values = [];
                  for (const value of filter.value) {
                    values.push(`"${value}"`);
                  }
                  where.push(`spi:${filter.field} in [${values.join(",")}]`);
                }
              }
              break;
            case "is":
            case "equals":
            case "=":
              filter.value &&
                where.push(`spi:${filter.field}="${filter.value}"`);
              break;
            case "isEmpty":
              where.push(`spi:${filter.field}="null"`);
              break;
            case "isNotEmpty":
              where.push(`spi:${filter.field}!="null"`);
              break;
            case "not":
            case "notEquals":
            case "!=":
              filter.value &&
                where.push(`spi:${filter.field}!="${filter.value}"`);
              break;
            default:
              return;
          }
        }
        where.length > 0 && url.searchParams.set("search", where.join(" and "));
      }

      if (sortModel.length > 0) {
        for (const sort of sortModel) {
          url.searchParams.set("sort", `spi:${sort.field},${sort.sort}`);
        }
      }

      try {
        const response = await fetch(url, { credentials: "include" });

        if (!response.ok) {
          if (response.status === 401) {
            window.location.replace(
              `${process.env.REACT_APP_API_HOST}/oauth2/authorization/duo`
            );
          }
          throw new Error();
        }

        const responseData = await response.json();

        let totalCount;
        if (responseData.hasOwnProperty("oslc:responseInfo")) {
          totalCount = responseData["oslc:responseInfo"]["oslc:totalCount"];
        } else {
          totalCount = pageSize * page + responseData[`rdfs:member`].length;
        }

        setData(responseData);
        setTotalCount(totalCount);
        setIsLoading(false);
        setError(false);
      } catch (error) {
        setError(true);
      }
    };
    getProperties();
  }, [
    config.path,
    config.queryCapability,
    config.linkedResource,
    page,
    pageSize,
    sortModel,
    filterModel,
  ]);

  const onSortChangeHandler = React.useCallback(
    (sortModel) => {
      setSortModel(sortModel);
      setIsLoading(true);
    },
    [setIsLoading, setSortModel]
  );

  const onFilterChangeHandler = React.useCallback(
    (filterModel) => {
      setFilterModel(filterModel);
      setIsLoading(true);
    },
    [setIsLoading, setFilterModel]
  );

  return {
    data,
    isLoading,
    error,
    totalCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortModel,
    onSortChangeHandler,
    filterModel,
    onFilterChangeHandler,
  };
};

export default useOslcQuery;
